body{
  margin-left: 20px;
  margin-top: 10px;
  margin-bottom: 20px;
}

.contact-card {
  display: flex;
  padding: 10px;
  color: #ffffff;
  background-color: rgb(42, 84, 104);
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  box-shadow: 10px 10px 25px -16px rgba(0, 0, 0, 0.75);
  border-radius: 10px;
  max-width: 500px;
  max-height: 125px;
  margin-bottom: 10px;
  align-items: center;
  justify-content: center;
}

.contact-card p {
  margin-left: 10px;
  margin-top: 0;

}

button {
  margin-left: 10px;
  margin-bottom: 10px;

}